import dataless from '../../../../scripts/cache/dateless.json';
import destinationsListCache from '../../../../scripts/cache/destinationsList.json';
import categoriesCache from '../../../../scripts/cache/categories.json';
import { getPublicStory } from '../../../../src/common/service/api/Stories/Stories';
import {
    createActivityPageStructuredData,
    createActivityReviews,
} from '@/entities/Activity/app/Creators';
import { getActivityById } from '@/entities/Activity/service/getActivityById';
import {
    ActivityPage,
    ActivityPageProps,
} from '../../../../src/screens/ActivityPage/ui/ActivityPage';
import { getFAQ } from '../../../../src/shared/FAQ/service/ApiFaq';
import { getActivityBreadcrumbs } from '../../../../src/entities/Activity/app/getActivityBreadcrumbs';
import { GetStaticProps } from 'next';
import { getFeaturedSectionTitle } from '@/shared/FeaturedActivities/ui/featuredActivitiesPreview/utils';
import { fetchUserImagesById, getPlaceholders } from '@/common/service/api/Activity/Activity';
import { isProd } from '@/common/app/constants/envs';
import { IActivityCategory } from '@/entities/Activity/domain/Activity.domain';
import { getActivityDateless } from '@/entities/Attractions/service/ApiAttractionsPage';
import { IActivityItem } from '@/common/service/api/Activity/Activity.domain';
import { removeDuplicate } from '@/common/app/utils/arrayUtils';
import { TLandmarkShort } from '@/screens/LandmarkMainPage/domain/Landmarks.domain';
import { fetchAllLandmarks } from '@/screens/LandmarkMainPage/service/fetchAllLandmarks';
import { getRedirectsPath } from '@/common/service/api/Redirects/getRedirectsPath';
import { getRecommends } from '@/shared/FeaturedActivities/service/ApiFeaturedActivities';

const BLACK_LIST = [
    'new-trip-fo-test',
    'test-4-activity',
    'test-quaternary-category',
    'test-trip-test',
];

type TActivityParams = {
    params: {
        ActivityId: string;
        ActivitySlug: string;
    };
};

const fetchAlsoViewed = async (
    destinationId: string,
    categories: IActivityCategory[],
    currentActivityId: string
) => {
    const activities: IActivityItem[] = [];
    const dateless = await getActivityDateless(destinationId);
    const allActivities = dateless.items || [];

    categories.forEach((i) => {
        activities.push(
            ...allActivities.filter((item) =>
                (item.activity_categories as unknown[] as string[]).includes(i.id)
            )
        );
    });

    return removeDuplicate([...activities, ...allActivities], 'id')
        .filter((item) => item.id !== currentActivityId)
        .slice(0, 12);
};

export async function getStaticPaths() {
    const dateLess: { [key: string]: { items: { id: string; slug: string }[] } } = dataless as {
        [key: string]: { items: { id: string; slug: string }[] };
    };

    try {
        const paths = destinationsListCache.reduce((result, destination) => {
            const activitiesList = dateLess[destination.id];

            const newParams = activitiesList.items.map((activity: { id: string; slug: string }) => {
                return {
                    params: {
                        ActivityId: activity.id,
                        ActivitySlug: activity.slug.toLowerCase(),
                    },
                };
            });

            return result.concat(newParams);
        }, [] as TActivityParams[]);

        return {
            paths:
                process.env.BUILD_FAST_ENV || !isProd
                    ? []
                    : paths.filter((v) => !BLACK_LIST.includes(v.params.ActivitySlug)),
            fallback: 'blocking',
        };
    } catch (error) {
        return {
            paths: [],
            fallback: 'blocking',
        };
    }
}

type PageStaticProps = {
    ActivityId: string;
    ActivitySlug: string;
};

export const getStaticProps: GetStaticProps<ActivityPageProps, PageStaticProps> = async ({
    params,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    try {
        if (!params) {
            throw new Error(`PAGE ACTIVITY: BAD PARAMS: ${params}`);
        }

        const { ActivityId, ActivitySlug } = params;
        const faqSlug = `${ActivitySlug}_details_${ActivityId}`;
        const isGiftCard = ActivityId === '1025';

        const [activity, userImages, stories, faq, landmarks] = await Promise.all([
            getActivityById({
                id: ActivityId,
                is_full: 1,
            }),
            fetchUserImagesById(ActivityId),
            getPublicStory({
                activity_id: ActivityId,
                limit: 4,
            }),
            getFAQ(faqSlug),
            fetchAllLandmarks({ trip_id: ActivityId }) as Promise<TLandmarkShort[]>,
        ]);

        const currentPath = `/${params.ActivitySlug}/details/${params.ActivityId}/`;

        if (!activity) {
            const redirectPath = await getRedirectsPath(currentPath);

            if (redirectPath === '/404/') {
                return {
                    notFound: true,
                };
            }

            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: redirectPath.toLowerCase(),
                    statusCode: 301,
                },
            };
        }

        const rightPath = `/${activity.slug}/details/${activity.id}/`.toLowerCase();

        if (currentPath !== rightPath) {
            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: rightPath,
                    statusCode: 301,
                },
            };
        }

        if (!activity.city_id || !activity.activity_categories?.length) {
            return {
                revalidate: Number(process.env.CACHE_ENV),
                notFound: true,
            };
        }

        const currentDestination =
            destinationsListCache.find((i) => i.id === activity.city_id) ||
            destinationsListCache.find((i) => i.id === activity.locations[0].city_id) ||
            destinationsListCache[0];

        if (!activity.is_active) {
            const defaultPath = currentDestination.categories.some(
                (i) => i.id === activity.activity_categories[0]?.id
            )
                ? activity.activity_categories[0].url
                : currentDestination.url_search;
            const redirectPath = await getRedirectsPath(defaultPath);

            if (redirectPath === '/404/') {
                return {
                    notFound: true,
                };
            }

            return {
                revalidate: Number(process.env.CACHE_ENV),
                redirect: {
                    destination: redirectPath.toLowerCase(),
                    statusCode: 301,
                },
            };
        }

        const activityReviews = createActivityReviews(activity);
        const breadcrumbs = getActivityBreadcrumbs(
            activity,
            currentDestination,
            activity.activity_categories[0]
        );
        const featuredActivitiesTitle = getFeaturedSectionTitle(currentDestination.name);

        const currentCategory =
            categoriesCache.find((v) => +v.id === +activity.activity_categories[0]?.id) || null;

        const structuredData = createActivityPageStructuredData(activity);

        const [alsoViewedActivities, categoryRecommends, recommends] = await Promise.all([
            fetchAlsoViewed(currentDestination.id, activity.activity_categories || [], ActivityId),
            getRecommends({
                category_id: currentCategory?.id,
                destination_id: currentDestination.id,
            }),
            getRecommends({
                destination_id: currentDestination.id,
            }),
        ]);

        if (categoryRecommends.length && currentCategory?.id) {
            categoryRecommends.forEach((i) => {
                i.category_id = `${currentCategory.id}`;
            });
        }

        const recommendedTrips = [
            ...categoryRecommends,
            ...recommends.filter((i) => !categoryRecommends.find((c) => i.id === c.id)),
        ];

        const ids = [
            ...recommendedTrips.map(({ id }) => id),
            ...alsoViewedActivities.map(({ id }) => id),
        ];

        const placeholders = await getPlaceholders(ids, 'activity');

        recommendedTrips.forEach((i) => {
            i.placeholder = placeholders[i.id] || '';
        });
        alsoViewedActivities.forEach((i) => {
            i.placeholder = placeholders[i.id] || '';
        });

        return {
            revalidate: 900,
            props: {
                breadcrumbs,
                activity,
                userImages,
                stories,
                activityReviews,
                currentDestination,
                currentCategory,
                structuredData,
                faq,
                isGiftCard,
                featuredActivitiesTitle,
                landmarks,
                recommendedTrips,
                recommendedIds: recommends.map(({ id }) => id),
                fullList: {
                    activity_ids: alsoViewedActivities.map(({ id }) => id).join(),
                    items: alsoViewedActivities,
                    total: alsoViewedActivities.length,
                },
            },
        };
    } catch (e) {
        throw new Error((e as Error).message);
    }
};

export default ActivityPage;
